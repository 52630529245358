import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Icon, Loading, Button, FormInput, ErrorLoadProjects } from 'interface'
import { Trans, t } from '@lingui/macro'
import { useRouter } from 'next/router'
import { useUserStore } from '../../../store/user'
import { useEvent } from '../../../hooks/analytics'
import { ModalUnlimitedUploads } from '../../../components/modal-unlimited-uploads'
import { trigger } from '../../../lib/events'
import { usePageVisibility } from '../../../hooks/misc/use-page-visibility'
import { useTasks } from '../../../hooks/tasks/use-tasks'
import { ProjectsList } from '../projects-list'

export const ProjectsPage: React.FC = () => {
  const { push, replace, query } = useRouter()
  const { user } = useUserStore()
  const { sendEvent } = useEvent()
  const pageIsActive = usePageVisibility()
  const {
    searchTasks: {
      value: valueSearch,
      onClear: onClearSearch,
      onChange: onChangeSearch
    },
    tasks: { loaderRef, loading, error, tracks, totalCount },
    isTasksLoaded
  } = useTasks({
    limit: 100,
    disabled: !pageIsActive
  })

  useEffect(() => {
    if (isTasksLoaded) {
      sendEvent({
        name: 'mastering-projects-viewed',
        category: 'engagement',
        customAttributes: {
          number_of_projects: tracks.length
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTasksLoaded])

  const handleRefresh = useCallback(() => trigger('revalidate:tasks'), [])

  const [isOpenModal, setIsOpenModal] = useState(false)
  const onOpenModal = useCallback(() => {
    setIsOpenModal(true)
  }, [])
  const onCloseModal = useCallback(() => {
    setIsOpenModal(false)
  }, [])

  const featureFlagPaywalls = useMemo(
    () => true, // user?.featureFlags?.webNewVoiceStudioPaywalls,
    []
  )

  const isPro = useMemo(() => user?.subscription?.isPro, [user])

  const availableCredits = useMemo(
    () => user?.subscription?.availableCredits || 0,
    [user]
  )

  const hasAvailableCredits = useMemo(
    () => availableCredits > 0,
    [availableCredits]
  )

  useEffect(() => {
    if (query?.noCreditsLeft) {
      replace('/', undefined, { shallow: true })
      onOpenModal()
    }
  }, [query, replace, onOpenModal])

  const onGoUploadPage = useCallback(() => {
    if (
      (featureFlagPaywalls && hasAvailableCredits) ||
      (!featureFlagPaywalls && isPro)
    ) {
      sendEvent({
        name: 'mastering-new-master-clicked',
        category: 'engagement'
      })
      return push('/upload')
    }

    return onOpenModal()
  }, [
    push,
    onOpenModal,
    sendEvent,
    hasAvailableCredits,
    featureFlagPaywalls,
    isPro
  ])

  if (!user) {
    return (
      <div className="flex w-screen h-screen justify-center items-center">
        <Loading />
      </div>
    )
  }

  if (error && !tracks.length) {
    return <ErrorLoadProjects onClickAction={handleRefresh} />
  }

  return (
    <div className="py-8 px-6 max-w-[1140px] mx-auto">
      <div className="md:flex md:items-center md:justify-between mb-9">
        <div className="min-w-0 flex-1">
          <h2 className="font-semibold leading-7 text-white text-[40px] sm:text-ellipsis sm:tracking-tight">
            <Trans>Mastering</Trans>
          </h2>
        </div>

        <div className="mt-4 flex flex-col md:flex-row md:ml-4 md:mt-0 gap-3">
          <div className="relative rounded-md shadow-sm">
            <FormInput
              id="search_field_searchbox_1"
              type="text"
              value={valueSearch}
              disabled={loading}
              icon={<Icon name="search" />}
              onChange={onChangeSearch}
              onClear={onClearSearch}
              placeholder={t`Search`}
            />
          </div>

          <Button onClick={onGoUploadPage}>
            <Icon name="plus" />
            <Trans>New Master</Trans>
          </Button>
        </div>
      </div>

      <ProjectsList
        loading={loading}
        tracks={tracks}
        totalCount={totalCount}
        valueSearch={valueSearch}
        onGoUploadPage={onGoUploadPage}
      />

      {isOpenModal && (
        <ModalUnlimitedUploads open={isOpenModal} onDismiss={onCloseModal} />
      )}

      {/* ref bottom page for pagination */}
      {!loading && tracks.length > 0 && loaderRef && <span ref={loaderRef} />}
    </div>
  )
}
