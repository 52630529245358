/* eslint no-console: 0 */
import { useCallback } from 'react'
import { useUserStore } from '../../../store/user'
import { Moises } from '../../../lib/graphql'
import { config } from '../../../config'

interface EventParams {
  name: string
  category?: string
  label?: string
  customAttributes?: {
    [key: string]: string | string[] | number | boolean | undefined
  }
}

interface EventTrackParams {
  data: {
    event: string
    payload: any
  }
}

interface UseEvent {
  sendEvent(event: EventParams): any
  eventTrack(data: EventTrackParams): Promise<void>
}

const isDesktopApp =
  typeof window !== 'undefined'
    ? navigator?.userAgent?.includes('MoisesDesktop')
    : false

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

export const useEvent = (): UseEvent => {
  const { user, userToken } = useUserStore()

  const sendEvent = useCallback(
    ({ name, category, label, customAttributes }: EventParams) => {
      if (!user?.id) {
        return
      }

      const extendedCustomAttributes = {
        ...customAttributes,
        user_id: user?.id,
        locale: navigator.language,
        // @ts-expect-error
        device_os: window?.navigator?.userAgentData?.platform || 'not set',
        client: isDesktopApp ? 'studio-desktop-app' : 'studio',
        timestamp: new Date().toISOString()
      }

      const data = {
        name,
        userId: user?.id,
        category: category || '',
        label,
        customAttributes: extendedCustomAttributes
      }

      const message = {
        type: 'events-mastering',
        payload: data
      }

      window?.parent?.postMessage(message, '*')
    },
    [user?.id]
  )

  const eventTrack = useCallback(
    async ({ data }: EventTrackParams) => {
      if (!userToken) return
      MoisesCLI.auth(userToken)

      const extendedCustomAttributes = {
        ...data.payload,
        user_id: user?.id,
        locale: navigator.language,
        // @ts-expect-error
        device_os: window?.navigator?.userAgentData?.platform || 'not set',
        client: isDesktopApp ? 'studio-desktop-app' : 'studio',
        timestamp: new Date().toISOString()
      }

      const event = {
        event: data.event,
        payload: extendedCustomAttributes
      }

      await MoisesCLI.eventTrack({
        data: event
      })
    },
    [userToken, user?.id]
  )

  return {
    sendEvent,
    eventTrack
  }
}
