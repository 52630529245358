import { Trans } from '@lingui/macro'
import { ModalConfirmation } from 'interface'
import React, { useCallback, useEffect } from 'react'
import { useEvent } from '../../hooks/analytics'

type Props = {
  open: boolean
  onDismiss: () => void
}

export const ModalUnlimitedUploads: React.FC<Props> = ({ open, onDismiss }) => {
  const { sendEvent } = useEvent()

  useEffect(() => {
    sendEvent({
      name: 'paywall_hit',
      category: 'growth',
      customAttributes: {
        source: 'paywall-Mastering-unlimitedUploads'
      }
    })
  }, [sendEvent])

  const handleConfirm = useCallback(() => {
    sendEvent({
      name: 'clicked_unlock_paywall',
      category: 'growth',
      customAttributes: {
        source: 'paywall-Mastering-unlimitedUploads'
      }
    })

    const isStage = global.window.location.href.includes('stage')

    window.open(
      `https://studio${
        isStage ? '-stage' : ''
      }.moises.ai/pricing/?source=paywall-Mastering-unlimitedUploads`,
      '_parent'
    )
  }, [sendEvent])

  return (
    <ModalConfirmation
      isOpen={open}
      onClose={onDismiss}
      onConfirm={handleConfirm}
      label="modal-mastering-unlimited-uploads"
      image="ic-mastering"
      title={<Trans>Unlock unlimited uploads</Trans>}
      info={
        <Trans>
          Upgrade now for unlimited uploads in Mastering, track separation, and
          more.
        </Trans>
      }
      confirmLabel={<Trans>Upgrade plan</Trans>}
      shouldCloseOnOverlayClick
      showBackground
    />
  )
}
