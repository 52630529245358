import React, { useEffect, useMemo, useState } from 'react'
import {
  EmptyProjects,
  EmptyState,
  Loading,
  ProjectListHeader,
  ProjectListItem
} from 'interface'
import { useRouter } from 'next/router'
import { Trans } from '@lingui/macro'
import {
  OperationBeatChordsResult,
  Task,
  TaskOperations
} from '../../../types/task.types'
import { useEvent } from '../../../hooks/analytics'
import { DropDownProjectList } from '../../../components/drop-down-project-list'
import { getDuration, masterOperations } from '../../../lib/misc/utils'

interface Props {
  loading: boolean
  tracks: Task[]
  totalCount: number
  valueSearch: string
  onGoUploadPage(): void
}

const getKey = (operations: TaskOperations[]): string => {
  const result = operations?.find((op) => op?.name?.includes('BEATSCHORDS'))
    ?.result as OperationBeatChordsResult
  return result?.key?.replace(' minor', 'm')?.replace(' major', '')
}

export const ProjectsList: React.FC<Props> = ({
  loading: _loading,
  tracks,
  totalCount,
  valueSearch,
  onGoUploadPage
}) => {
  const { push } = useRouter()
  const { sendEvent } = useEvent()

  // we need to set loading true on first load
  // to not show the empty state until
  // the tracks is loaded
  const [loading, setLoading] = useState(true)
  useEffect(() => setLoading(_loading), [_loading])

  const projectsNotFound = useMemo(
    () => !loading && tracks.length === 0 && valueSearch,
    [loading, tracks, valueSearch]
  )

  const empty = useMemo(
    () => !loading && tracks.length === 0 && !valueSearch,
    [loading, tracks, valueSearch]
  )

  useEffect(() => {
    if (!loading && valueSearch) {
      sendEvent({
        name: 'mastering-projects-searched',
        category: 'engagement',
        customAttributes: {
          number_of_results: tracks.length
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <>
      {loading && !tracks.length ? (
        <Loading className="mx-auto" />
      ) : projectsNotFound ? (
        <EmptyState
          id="project-not-found"
          ariaLabel="project not found"
          title={<Trans>Nothing found</Trans>}
          description={
            <Trans>You don’t have a project called “{valueSearch}”</Trans>
          }
        />
      ) : empty ? (
        <EmptyProjects onClickAction={onGoUploadPage} />
      ) : tracks.length > 0 ? (
        <>
          <ProjectListHeader projectCount={totalCount} />

          <div className="flex flex-col gap-2 mt-2">
            {tracks.map((track) => {
              const data = {
                name: track.file.name,
                key: getKey(track.operations),
                duration: getDuration(track.summary)
              }
              const operations = track?.operations?.filter(({ name }) =>
                masterOperations.includes(name)
              )
              return (
                <ProjectListItem
                  key={track.id}
                  data={data}
                  onListItemClick={() => push(`/project/${track.id}`)}
                  actionDropdown={
                    <DropDownProjectList
                      id={track.id}
                      name={track.file.name}
                      operations={operations}
                    />
                  }
                />
              )
            })}
          </div>
        </>
      ) : null}
    </>
  )
}
