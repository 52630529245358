import React from 'react'
import { getServerSideProps } from '../lib/misc/get-server-side-props'
import { ProjectsPage } from '../modules/projects/projects-page'

const page: React.FC = () => {
  return <ProjectsPage />
}

export default page

export { getServerSideProps }
